import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Container } from "react-bootstrap";
import { IImage, IParagraph } from "../models/cmsModels";
import ImageGallery from "../components/shared/ImageGallery/image-gallery";
import SEO from "../components/seo";

const Privacy = ({data}) => {

    const {privacy: {edges}} = data;
    const { privacypolicyTitle, privacypolicyDescription } = data?.seo?.nodes[0];

    const [galleryImages, setGalleryImages] = useState([] as IImage[]);
    const [imageIndex, setImageIndex] = useState(null as number);
    const [isImageGalleryOpen, setImageGalleryOpen] = useState(false);

    const {node: {paragraphs, title} } = edges[0];

    const paragraphStyle: any = (paragraph: IParagraph) => {
        let direction = '';
    
        switch (paragraph.imagesOrientation) {
          case 'Left': direction = 'row-reverse'; break;
          case 'Right': direction = 'row'; break;
          case 'Top': direction = 'column-reverse'; break;
          case 'Bottom': direction = 'column'; break;
          default: direction = 'column'; break;
        }
    
        return {
          display: 'flex',
          flexDirection: direction
        }
    }

    const imagesStyle: any = (paragraph: IParagraph) => {
        let direction = '';
    
        switch (paragraph.imagesOrientation) {
          case 'Left':
          case 'Right': direction = 'column'; break;
          case 'Top': 
          case 'Bottom': direction = 'row'; break;
          default: direction = 'row'; break;
        }
    
        return {
          display: 'flex',
          flexDirection: direction
        }
    };


    const openImageGallery = (images: IImage[], index: number) => {
        setGalleryImages(images);
        setImageIndex(index);
        setImageGalleryOpen(true);
    };

    const closeImageGallery = () => {
        setGalleryImages([]);
        setImageIndex(null);
        setImageGalleryOpen(false);
    };
    
  return (
    <Layout>
        <SEO title={privacypolicyTitle} description={privacypolicyDescription}></SEO>
        <Container>
            {
                !isImageGalleryOpen ? <></> :
                <ImageGallery images={galleryImages} defaultIndex={imageIndex} onClose={() => closeImageGallery()} />
            }
            <h2>{title}</h2>
            {
                !paragraphs ? <></> :
                paragraphs.map((para: IParagraph, p: number) => (
                <div className="gl-paragraph" style={paragraphStyle(para)}>
                    <div key={p} className='gl-contents' dangerouslySetInnerHTML={{__html: para.contents}}></div>
                    <div className="gl-images" style={imagesStyle(para)}>
                        {
                        para.images.map((image, i: number) => (
                            <>
                              <img key={i} width={image.width/2} src={image.url} onClick={() => openImageGallery(para.images, i)} title="Click to enlarge"/>
                            </>
                        ))
                        } 
                    </div>
                </div>
                ))
            }
      </Container>
    </Layout>
  )
}



export const query = graphql`
  {
    privacy: allStrapiPrivacy {
        edges {
          node {
            title
            paragraphs {
              contents
              images {
                name
                width
                height
                url
              }
            }
          }
        }
      },
      seo: allStrapiSeo {
        nodes {
          privacypolicyDescription
          privacypolicyTitle
        }
      },
  }
`



export default Privacy;
